@import '../styles/_variables.scss';


// This file is for edge case use only. All classes should be applied at the
// component level or within SCSS variables

// TODO remove when Snackbar supports extraClasses
snack-bar-container {
  background-color: $cfaErrorRed;
}

md-dialog .md-button:not([disabled]):hover {
    background-color: $cfaErrorRed !important;
    color: $cfaWhite !important;
}

md-dialog .md-button.md-default-theme.md-primary, .md-button.md-primary {
    background-color: $cfaErrorRed !important;
    color: $cfaWhite !important;
}

// TODO remove when Slide-Toggle supports extraClasses
.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $cfaPrimaryFade !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $cfaPrimary !important;
  }
}

.mat-checked.mat-disabled {
  .mat-slide-toggle-bar {
    background-color: $cfaGray !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $cfaDarkGray !important;
  }
}

.toggle-trends.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-red  !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-red !important;
  }
}

.toggle-customer.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-green  !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-green !important;
  }
}

.toggle-treat.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-blue !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-blue !important;
  }
}

.toggle-event.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-salmon !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-salmon !important;
  }
}

.toggle-email.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-purple !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-purple !important;
  }
}

.toggle-catering.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-orange !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-orange !important;
  }
}

.toggle-care.mat-checked {
  .mat-slide-toggle-bar {
    background-color: $understand-light-care-blue !important;
  }
  .mat-slide-toggle-thumb {
    background-color: $understand-care-blue !important;
  }
}

.DateRangePickerInput--disabled {
  background: $cfaWhite !important;
}

.DateInput--disabled {
  background: $cfaWhite !important;

  div {
    color: rgba(0, 0, 0, 0.38) !important;
  }
}

.mat-tooltip {
  background-color: $cfaPrimary;
  color: $cfaWhite !important;
  font-size: 11px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $cfaPrimary;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background {
  background-color: $cfaDarkGray;
}

.mat-tab-body {
  overflow: hidden !important;
}

.mat-tab-body-content {
  overflow: initial !important;
}

.mat-card {
  margin: 8px;
}

.mat-option.mat-selected {
  color: $cfaPrimary;
}

.mat-divider {
  border-top-style: solid;
  border-top-width: 1px;
}

.mat-select.mat-select-disabled .mat-select-arrow {
  color: rgba(0, 0, 0, 0.54);
}

//.mat-form-field-infix {
//  border-top: none;
//  font-size: 16px;
//}
//
//.mat-input-element {
//  font-size: 16px;
//}

.mat-input-infix {
  position: unset !important;
}

//.mat-form-field-underline.mat-disabled {
//  background-color: rgba(0, 0, 0, 0.42) !important;
//  background-image: none;
//}

.mat-icon {
  color: rgba(0,0,0,0.54);
}

.mat-nav-list .mat-list-item .mat-list-item-content {
  height: 100% !important;
  width: 100%;
  min-height: 55px;
}

.mat-ink-bar.mat-ink-bar {
  background-color: $cfaPrimary !important;
}

.mat-progress-spinner circle, .mat-spinner circle {
  stroke: $cfaPrimary !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: $cfaPrimary;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  color: $cfaPrimary;
  background-color: $cfaPrimary;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: $cfaPrimaryFade;
}

.mat-select-value-text {
  color: $cfaPrimary;
}

.mat-checkbox .mat-checkbox-inner-container {
  height: 20px;
  width: 20px;
}

label {
  font-weight: inherit;
}

.mat-card-avatar {
  object-fit: unset !important;
}

.mat-card-header .mat-card-title {
  margin-bottom: 0 !important;
}

.mat-nav-list .mat-list-item {
  height: auto !important;
}

.mat-nav-list .mat-list-item .mat-list-avatar {
  flex-shrink: initial !important;
}

.mat-card .mat-divider-horizontal {
  position: initial !important;
}

.mat-chip-list-wrapper {
  margin: 0 !important;
}

a:not([href]):not([tabindex]) {
  color: #337ab7;
}

// This is to remove a blue outline in Safari on click
input:focus, textarea:focus, mat-select:focus {
  outline: none !important;
}

// Only way to apply styling to inside the cfa calendar component
.calendar-subtext {
  color: black;
  font-size: 11px;
}

.cfa-calendar-menu {
  text-align: left;
}

.bottom-sheet-container {
  padding: 0 !important;
}

.cfa-calendar-wrapper {
  height: 97% !important
}
