@import './cfa-theme.scss';

//@import 'bootstrap-sass';
@import 'font-awesome/css/font-awesome.min.css';

@import '../assets/css/app.css';
@import './globals.scss';
@import 'quill/dist/quill.snow.css';
@import 'quill/dist/quill.core.css';

.cfa-nowrap-row {
  width: 100% !important;
  margin: 0 !important;
}

.cfa-12 {
  font-size: 12px;
  line-height: 12px;
}
.row:before, .row:after {display: none !important;}

mat-card { margin: 10px !important;}

mat-slide-toggle {
  transform: translate(-14.5%, -14.5%) scale(0.7);
  margin-right:-5px
}

mat-form-field {
  margin-top: 3px !important;
}

.dialog-responsive {
  max-width: none !important;
  min-width:100%
}

