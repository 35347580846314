@use '@angular/material' as mat;


$cfa-palette: (

        0: #000000,
        10: #001b3f,
        20: #002f65,
        25: #003a7a,
        30: #00458f,
        35: #0050a5,
        40: #004F71,
        50: #0074e9,
        60: #438fff,
        70: #7cabff,
        80: #abc7ff,
        90: #d7e3ff,
        95: #ecf0ff,
        98: #f9f9ff,
        99: #fdfbff,
        100: #ffffff,
        secondary: (
                0: #000000,
                10: #131c2b,
                20: #283041,
                25: #333c4d,
                30: #3e4759,
                35: #4a5365,
                40: #565e71,
                50: #6f778b,
                60: #8891a5,
                70: #a3abc0,
                80: #bec6dc,
                90: #dae2f9,
                95: #ecf0ff,
                98: #f9f9ff,
                99: #fdfbff,
                100: #ffffff,
        ),
        neutral: (
                0: #000000,
                10: #1a1b1f,
                20: #2f3033,
                25: #3b3b3f,
                30: #46464a,
                35: #525256,
                40: #5e5e62,
                50: #77777a,
                60: #919094,
                70: #ababaf,
                80: #c7c6ca,
                90: #e3e2e6,
                95: #f2f0f4,
                98: #ffffff,
                99: #fdfbff,
                100: #ffffff,
                4: #0d0e11,
                6: #121316,
                12: #1f2022,
                17: #292a2c,
                22: #343537,
                24: #38393c,
                87: #dbd9dd,
                92: #fafafa,
                94: #FFFFFF,
                96: #ffffff,
        ),
        neutral-variant: (
                0: #000000,
                10: #181c22,
                20: #2d3038,
                25: #383b43,
                30: #44474e,
                35: #4f525a,
                40: #5b5e66,
                50: #74777f,
                60: #ffffff,
                70: #ffffff,
                80: #ffffff,
                90: #ffffff,
                95: #ffffff,
                98: #ffffff,
                99: #ffffff,
                100: #ffffff,
        ),  error: (
                0: #000000,
                10: #410002,
                20: #690005,
                25: #7e0007,
                30: #93000a,
                35: #a80710,
                40: #ba1a1a,
                50: #de3730,
                60: #ff5449,
                70: #ff897d,
                80: #ffb4ab,
                90: #ffdad6,
                95: #ffedea,
                98: #fff8f7,
                99: #fffbff,
                100: #ffffff,
        )
);


$my-theme: mat.define-theme((
        color: (
                theme-type: light,
                primary: $cfa-palette
        ),
        typography: (
                plain-family: "Apercu, sans-serif",
                bold-weight: 900,
        ),
));


html {
  @include mat.all-component-themes($my-theme);
  @include mat.color-variants-backwards-compatibility($my-theme);
  @include mat.core();
}


